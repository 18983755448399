import styled, { css } from 'styled-components';

const SolutionsGridWrapper = styled.div`
  padding: 2rem 0;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 5px;
  margin-top: 5px;
  ${({ template }) =>
    template &&
    css`
      grid-template-columns: ${template};
    `}
  ${({ row }) =>
    row &&
    css`
      margin-top: 0px;
      grid-auto-flow: row;
    `}
  @media only screen and (max-width: 667px) {
    display: block;
  }
`;

export const SolutionWrapper = styled.div`
  display: grid;
  position: relative;
  min-height: 60vh;
  height: 100%;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  z-index: 1;
  @media only screen and (max-width: 667px) {
    margin-top: 5px;
  }
  a {
    padding: 3.75rem 1.875rem;
  }
  .solution-info {
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    ${({ fullWidth }) =>
      fullWidth &&
      css`
        max-width: 100%;
      `}
    h3, h4 {
      color: ${({ theme }) => theme.colors.white};
      font-size: 30px;
      line-height: 35px;
      font-weight: 900;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      color: ${({ theme }) => theme.colors.white};
      margin-bottom: 10px;
      font-size: 1rem;
      line-height: 20px;
      font-weight: 300;
    }
    @media only screen and (max-width: 667px) {
      max-width: 100%;
    }
  }

  ${({ featured }) =>
    featured &&
    css`
      @media only screen and (min-width: 668px) and (max-width: 980px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
      .solution-info {
        max-width: 65%;
      }
    `}

  ${({ height }) =>
    height &&
    css`
      min-height: ${height};
    `}
  ${({ cover }) =>
    cover &&
    css`
      background-image: url(${cover});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 2px;
      &:before {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.normalLayer};
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        border-radius: 2px;
        transition: 0.3s all;
      }
      &:hover {
        :before {
          background-color: ${({ theme }) => theme.colors.lightBlueLayer};
        }
    `}
`;

export default SolutionsGridWrapper;
