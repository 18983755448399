import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import Container from '../../components/UI/Container';
import ResourcesBannerWrapper, { GridContainer, ResourceWrapper } from './resourcesBanner.style';
import FeaturedResource from './FeaturedResource';
import SubFeaturedResources from './SubFeaturedResources';

const ResourcesBanner = ({ heading, resources = [] }) => {
  if (resources.length < 1) {
    return null;
  }
  const featuredResources = resources.slice(0, 3);
  return (
    <ResourcesBannerWrapper>
      <Container>
        <GridContainer template="70% auto">
          <div className="inner-heading">
            <h3>Recursos</h3>
            <p>
              Revise algunos de nuestros recursos para
              {` ${heading}`}
            </p>
          </div>
          <div className="inner-nav">
            <Link to="/recursos">
              Todos los recursos 
              {' '}
              <i className="flaticon-next" />
            </Link>
          </div>
        </GridContainer>
        {resources.length === 1 && <FeaturedResource resource={resources[0]} />}
        {resources.length === 2 && <SubFeaturedResources resources={resources} />}
        {resources.length > 2 && (
          <GridContainer template={['2fr 1fr 1fr', '1fr 1fr']}>
            {featuredResources.map((resource, index) => {
              const isFirst = index === 0;
              const {
                excerpt,
                frontmatter: { title, cover, slug, tags }
              } = resource;
              return (
                <ResourceWrapper featured={isFirst} key={`${slug}-${title}`}>
                  {isFirst && (
                    <div className="resource-img">
                      <Image src={cover} alt={`SolarPower ${title}`} />
                    </div>
                  )}
                  <div className="resource-labels">
                    {tags.map((tag, i) => {
                      const isLast = i === tags.length - 1;
                      return (
                        <Fragment key={`${slug}-${tag}`}>
                          <span>{tag}</span>
                          {!isLast && <span className="divider">|</span>}
                        </Fragment>
                      );
                    })}
                  </div>
                  <div className="resource-info">
                    <Link to={`/recursos/${slug}`}>
                      <h4>{title}</h4>
                    </Link>
                    <p>{excerpt}</p>
                  </div>
                </ResourceWrapper>
              );
            })}
          </GridContainer>
        )}
      </Container>
    </ResourcesBannerWrapper>
  );
};

export default ResourcesBanner;
