import styled from 'styled-components';

const CtaBannerWrapper = styled.div`
  text-align: center;
  padding: 6rem 0;
  background-color: ${({ theme }) => theme.colors.blue};
  @media (max-width: 575px) {
    padding: 4rem 0;
  }

  h3 {
    color: ${({ theme }) => theme.colors.white};
    ${'' /* font-size: 3.125rem; */}
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 2rem;
    @media (max-width: 575px) {
      font-size: 2rem;
    }
  }

  .contact-btn {
    margin-left: 15px;
    transition: 0.2s all;
    &:hover {
      background-color: #fff;
      border-color: #fff;
      color: ${({ theme }) => theme.colors.textColor};
      box-shadow: ${({ theme }) => theme.colors.baseBoxShadow};
    }
    @media (max-width: 575px) {
      margin-left: 0;
      margin-top: 15px;
    }
  }
`;

export default CtaBannerWrapper;
