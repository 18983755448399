import React from 'react';
import { Link } from 'gatsby';
// import Button from 'reusecore/src/elements/Button';
import SolutionsGridWrapper, { GridContainer, SolutionWrapper } from './solutionsgrid.style';

const SolutionsGrid = ({ path, categories }) => {
  const [first, second, third, ford, ...rest] = categories;
  if (categories.length > 2) {
    return (
      <SolutionsGridWrapper>
        <GridContainer template="60% auto">
          <SolutionWrapper featured cover={first.cover}>
            <Link to={`/${path}/${first.category_slug}`}>
              <div className="solution-info">
                <h3>{first.title}</h3>
              </div>
            </Link>
          </SolutionWrapper>
          <SolutionWrapper height="70vh" cover={second.cover}>
            <Link to={`/${path}/${second.category_slug}`}>
              <div className="solution-info">
                <h3>{second.title}</h3>
              </div>
            </Link>
          </SolutionWrapper>
        </GridContainer>
        <GridContainer
          template={ford ? 'auto 60%' : 'auto'}
          // template={[2 / 3, 1 / 3]}
        >
          <SolutionWrapper featured cover={third.cover}>
            <Link to={`/${path}/${third.category_slug}`}>
              <div className="solution-info">
                <h3>{third.title}</h3>
              </div>
            </Link>
          </SolutionWrapper>
          {ford && (
            <SolutionWrapper height="70vh" cover={ford.cover}>
              <Link to={`/${path}/${ford.category_slug}`}>
                <div className="solution-info">
                  <h3>{ford.title}</h3>
                </div>
              </Link>
            </SolutionWrapper>
          )}
        </GridContainer>
        {rest && rest.length > 0 && (
          <GridContainer>
            {rest.map(category => {
              return (
                <SolutionWrapper key={category.title} height="70vh" cover={category.cover}>
                  <Link to={`/${path}/${category.category_slug}`}>
                    <div className="solution-info">
                      <h3>{category.title}</h3>
                    </div>
                  </Link>
                </SolutionWrapper>
              );
            })}
          </GridContainer>
        )}
      </SolutionsGridWrapper>
    );
  }
  return (
    <SolutionsGridWrapper>
      {categories.map(category => {
        return (
          <GridContainer template="auto" key={category.title}>
            <SolutionWrapper height="70vh" cover={category.cover}>
              <Link to={`/${path}/${category.category_slug}`}>
                <div className="solution-info">
                  <h3>{category.title}</h3>
                </div>
              </Link>
            </SolutionWrapper>
          </GridContainer>
        );
      })}
    </SolutionsGridWrapper>
  );
};

export default SolutionsGrid;
