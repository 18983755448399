import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import { GridContainer, ResourceWrapper } from './resourcesBanner.style';

const FeaturedResource = ({ resource }) => {
  if (!resource) {
    return null;
  }
  const {
    excerpt,
    frontmatter: { slug, title, cover, tags }
  } = resource;
  return (
    <GridContainer template="auto">
      <ResourceWrapper featured>
        <div className="resource-img">
          <Image src={cover} alt={`SolarPower ${title}`} />
        </div>

        <div className="resource-labels">
          {tags.map((tag, index) => {
            const isLast = index === tags.length - 1;
            return (
              <Fragment key={`${slug}-${tag}`}>
                <span>{tag}</span>
                {!isLast && <span className="divider">|</span>}
              </Fragment>
            );
          })}
        </div>

        <div className="resource-info">
          <Link to={`/recursos/${slug}`}>
            <h4>{title}</h4>
          </Link>
          <p>{excerpt}</p>
        </div>
      </ResourceWrapper>
    </GridContainer>
  );
};

export default FeaturedResource;
