import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import { GridContainer, ResourceWrapper } from './resourcesBanner.style';

import FeaturedResource from './FeaturedResource';

const SubFeaturedResources = ({ resources = [] }) => {
  if (resources.length === 0) {
    return null;
  }
  if (resources.length === 1) {
    return <FeaturedResource resource={resources[0]} />;
  }
  return (
    <GridContainer template="1fr 1fr">
      {resources.map((resource, index) => {
        const isFirst = index === 0;
        const {
          excerpt,
          frontmatter: { slug, title, tags, cover }
        } = resource;
        return (
          <ResourceWrapper featured={isFirst} key={`${slug}-${title}`}>
            {isFirst && (
              <div className="resource-img">
                <Image src={cover} alt={`SolarPower ${title}`} />
              </div>
            )}
            <div className="resource-labels">
              {tags.map((tag, i) => {
                const isLast = i === tags.length - 1;
                return (
                  <Fragment key={`${slug}-${tag}`}>
                    <span>{tag}</span>
                    {!isLast && <span className="divider">|</span>}
                  </Fragment>
                );
              })}
            </div>

            <div className="resource-info">
              <Link to={`/recursos/${slug}`}>
                <h4>{title}</h4>
              </Link>
              <p>{excerpt}</p>
            </div>
          </ResourceWrapper>
        );
      })}
    </GridContainer>
  );
};

export default SubFeaturedResources;
